import Sweetalert2 from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'

/**
 * Global options.
 */
const options = {
	cancelButtonColor: '#dddddd',
	reverseButtons: true,
}

export default Sweetalert2.mixin(options)
