import * as types from '@/store/mutation-types'

/**
 * @module store/referral/mutations
 */

export default {
	[types.REFERRAL_RESPONSE](state, payload) {
		state.referral.response = payload
	},
}
