import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/routes'
import { debounce } from 'lodash'
import store from '../store/store.js'

Vue.use(VueRouter)

export const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

const smoothScrollToTop = debounce(() => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	})
}, 200)

router.beforeEach((toRoute, fromRoute, next) => {
	window.document.title =
		toRoute.meta && toRoute.meta.title
			? toRoute.meta.title + ' | St Aust Cares'
			: 'St Aust Cares'

	if (toRoute.meta && toRoute.meta.keywords) {
		let metaKeywordsTag = document.querySelector('meta[name="keywords"]')
		if (!metaKeywordsTag) {
			metaKeywordsTag = document.createElement('meta')
			metaKeywordsTag.name = 'keywords'
			document.head.appendChild(metaKeywordsTag)
		}
		metaKeywordsTag.content = toRoute.meta.keywords
	}

	if (fromRoute.name) {
		smoothScrollToTop()
	}

	// Authorization check for admin routes
	const token = store.getters['auth/getApiToken']
	if (toRoute.meta.requiresAuth && !token) {
		next({ name: 'admin-login' })
	} else {
		next()
	}

	next()
})

Vue.router = router

export default {
	router,
}
