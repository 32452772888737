import axios from '@/plugins/axios'

export default (payload) => {
	const url = '/api/v1/admin/sales/mass-email'

	let config = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		params: {},
	}

	return axios.post(url, payload, config)
}
