import axios from '@/plugins/axios'

export default (payload) => {
	const url = '/api/v1/admin/login'

	let config = {
		headers: {},
		params: {},
	}

	return axios.post(url, payload, config)
}
