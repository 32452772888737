import Vue from 'vue'
import axios from 'axios'
import store from '../store/store.js'

Vue.$http = axios

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL

axios.interceptors.request.use(
	(config) => {
		// Set accepted content type.
		config.headers['Accept'] = 'application/json'

		// Conditionally set authorization header if the request is for the admin route.
		// if (config.url.includes('/admin')) {
		const token = store.getters['auth/getApiToken']
		config.headers['Authorization'] = `Bearer ${token}`
		// }

		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

export default axios
