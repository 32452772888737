import * as types from '@/store/mutation-types'
import ApiRequest from '@/services/services'
import commonActions from '@/store/common/common-actions'

/**
 * @module store/referral/actions
 */

export default {
	sendReferral({ commit }, payload) {
		ApiRequest.referral
			.sendReferral(payload)
			.then((response) => {
				commit(types.REFERRAL_RESPONSE, response)
			})
			.catch((error) => {
				commonActions.displayErrorMessage({}, error.response)
			})
	},
}
