import * as types from '../mutation-types'
import ApiRequest from '@/services/services'

export default {
	sendMassEmail({ commit, dispatch }, payload) {
		ApiRequest.admin.sales
			.sendMassEmail(payload)
			.then((response) => {
				commit(types.SEND_MASS_EMAIL_RESPONSE, response)
			})
			.catch((error) => {
				const errorResponse = error.response
				console.log(errorResponse)
				dispatch('common/displayErrorMessage', errorResponse, {
					root: true,
				})
			})
	},
}
