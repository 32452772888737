export default [
	{
		path: '/',
		name: 'home',
		component: () =>
			import(/* webpackChunkName: "home" */ '@/views/home/home-index'),
		meta: {
			requiresAuth: false,
			title: 'NDIS Service Provider in Melbourne',
			keywords: 'NDIS, Melbourne, disability services, support services',
		},
	},
	// ! SERVICES
	{
		path: '/services/ndis',
		name: 'ndis',
		component: () =>
			import(/* webpackChunkName: "ndis" */ '@/views/services/ndis/ndis-index'),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services',
			keywords: 'NDIS services, disability support, Melbourne, NDIS provider',
		},
	},
	// ! NDIS
	{
		path: '/services/ndis/accomodation-tenancy',
		name: 'accomodation-tenancy',
		component: () =>
			import(
				/* webpackChunkName: "accomodation-tenancy" */ '@/views/services/ndis/accomodation-tenancy'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | Accommodation / Tenancy',
			keywords:
				'NDIS, accommodation, tenancy, support services, disability services',
		},
	},
	{
		path: '/services/ndis/daily-tasks',
		name: 'daily-tasks',
		component: () =>
			import(
				/* webpackChunkName: "daily-tasks" */ '@/views/services/ndis/daily-tasks'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | Daily Tasks',
			keywords:
				'NDIS, daily tasks, support services, disability services, assistance',
		},
	},
	{
		path: '/services/ndis/travel-transportation',
		name: 'travel-transportation',
		component: () =>
			import(
				/* webpackChunkName: "travel-transportation" */ '@/views/services/ndis/travel-index'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | Travel / Transportation',
			keywords:
				'NDIS, travel, transportation, support services, disability services',
		},
	},
	{
		path: '/services/ndis/personal-activities',
		name: 'personal-activities',
		component: () =>
			import(
				/* webpackChunkName: "personal-activities" */ '@/views/services/ndis/personal-activities'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | Personal Activities',
			keywords:
				'NDIS, personal activities, support services, disability services',
		},
	},
	{
		path: '/services/ndis/high-intensity-activities',
		name: 'high-intensity-activities',
		component: () =>
			import(
				/* webpackChunkName: "high-intensity-activities" */ '@/views/services/ndis/personal-activities-hit'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | High Intensity Personal Activities',
			keywords:
				'NDIS, high intensity activities, support services, disability services',
		},
	},
	{
		path: '/services/ndis/group-activities',
		name: 'group-activities',
		component: () =>
			import(
				/* webpackChunkName: "group-activities" */ '@/views/services/ndis/group-activities'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | Group / Centre Activities',
			keywords:
				'NDIS, group activities, center activities, support services, disability services',
		},
	},
	{
		path: '/services/ndis/household-tasks',
		name: 'household-tasks',
		component: () =>
			import(
				/* webpackChunkName: "household-tasks" */ '@/views/services/ndis/household-tasks'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | Household Tasks',
			keywords: 'NDIS, household tasks, support services, disability services',
		},
	},
	{
		path: '/services/ndis/community-activities',
		name: 'community-activities',
		component: () =>
			import(
				/* webpackChunkName: "community-activities" */ '@/views/services/ndis/community-social-activities'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | Community and Civic Activities',
			keywords:
				'NDIS, community activities, civic activities, support services, disability services',
		},
	},
	{
		path: '/services/ndis/life-stages',
		name: 'life-stages',
		component: () =>
			import(
				/* webpackChunkName: "life-stages" */ '@/views/services/ndis/life-stages'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | Life Stages',
			keywords: 'NDIS, life stages, support services, disability services',
		},
	},
	{
		path: '/services/ndis/community-nursing-care',
		name: 'community-nursing-care',
		component: () =>
			import(
				/* webpackChunkName: "community-nursing-care" */ '@/views/services/ndis/community-nursing'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | Community Nursing Care',
			keywords:
				'NDIS, community nursing care, 24 hour care, support services, disability services',
		},
	},
	{
		path: '/services/ndis/community-participation',
		name: 'community-participation',
		component: () =>
			import(
				/* webpackChunkName: "community-participation" */ '@/views/services/ndis/community-participation'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | Community Participation',
			keywords:
				'NDIS, community participation, support services, disability services',
		},
	},
	{
		path: '/services/ndis/supported-independent-living',
		name: 'supported-independent-living',
		component: () =>
			import(
				/* webpackChunkName: "supported-independent-living" */ '@/views/services/ndis/supported-independent-living'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | Supported Independent Living',
			keywords:
				'NDIS, supported independent living, support services, disability services',
		},
	},
	{
		path: '/services/ndis/life-development',
		name: 'life-development',
		component: () =>
			import(
				/* webpackChunkName: "life-development" */ '@/views/services/ndis/life-development'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | Life Development',
			keywords: 'NDIS, life development, support services, disability services',
		},
	},
	{
		path: '/services/ndis/assist-access',
		name: 'assist-access',
		component: () =>
			import(
				/* webpackChunkName: "assist-access" */ '@/views/services/ndis/assist-access'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Services | Assist Access',
			keywords: 'NDIS, assist access, support services, disability services',
		},
	},
	// ! END OF NDIS
	{
		path: '/services/recruitment',
		name: 'recruitment-labour',
		component: () =>
			import(
				/* webpackChunkName: "recruitment-labour" */ '@/views/services/recruitment-labour/recruitment-labour'
			),
		meta: {
			requiresAuth: false,
			title: 'Recruitment Labour',
			keywords:
				'recruitment labour, staffing solutions, workforce supply, labour hire',
		},
	},
	{
		path: '/services/product',
		name: 'product-supply',
		component: () =>
			import(
				/* webpackChunkName: "product-supply" */ '@/views/services/product-supplies/product-supplies'
			),
		meta: {
			requiresAuth: false,
			title: 'Product Supply',
			keywords:
				'product supply, materials, goods, services, product distribution',
		},
	},
	{
		path: '/about',
		name: 'about',
		component: () =>
			import(/* webpackChunkName: "about" */ '@/views/about/about-index'),
		meta: {
			requiresAuth: false,
			title: 'About Us',
			keywords: 'about us, company information, mission, vision, values, ndis',
		},
	},
	{
		path: '/contact-us',
		name: 'contact',
		component: () =>
			import(/* webpackChunkName: "contact" */ '@/views/contact/contact-index'),
		meta: {
			requiresAuth: false,
			title: 'Connect with Us',
			keywords:
				'contact us, get in touch, support, assistance, customer service',
		},
	},
	{
		path: '/careers',
		name: 'careers',
		component: () =>
			import(/* webpackChunkName: "careers" */ '@/views/careers/careers-index'),
		meta: {
			requiresAuth: false,
			title: 'Work with Us',
			keywords:
				'careers, job opportunities, employment, work with us, vacancies',
		},
	},
	{
		path: '/referral',
		name: 'referral',
		component: () =>
			import(
				/* webpackChunkName: "referral" */ '@/views/referral/referral-index'
			),
		meta: {
			requiresAuth: false,
			title: 'Referrals',
			keywords: 'referrals, NDIS, referral program, support services,',
		},
	},
	// ! ADMIN
	{
		path: '/admin-10b2e6b0-d420-427c-a318-e0098efaa4b0/login',
		name: 'admin-login',
		component: () =>
			import(/* webpackChunkName: "admin-login" */ '@/views/admin/admin-login'),
		meta: {
			requiresAuth: false,
			title: 'Admin',
			keywords: '',
		},
	},
	{
		path: '/admin-10b2e6b0-d420-427c-a318-e0098efaa4b0',
		name: 'admin',
		component: () =>
			import(/* webpackChunkName: "admin" */ '@/views/admin/admin-index'),
		meta: {
			requiresAuth: true,
			title: 'Admin',
			keywords: '',
		},
	},
	{
		path: '/admin-10b2e6b0-d420-427c-a318-e0098efaa4b0/mail',
		name: 'admin-mail',
		component: () =>
			import(
				/* webpackChunkName: "admin-mail" */ '@/views/admin/sales/email-index'
			),
		meta: {
			requiresAuth: true,
			title: 'Admin',
			keywords: '',
		},
	},

	// SEO
	// ! Sunshine
	{
		path: '/service-areas/community-nursing-sunshine',
		name: 'ndis-community-nursing-sunshine',
		component: () =>
			import(
				/* webpackChunkName: "ndis-community-nursing-sunshine" */ '@/views/services/ndis/location/sunshine/community-nursing'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Community Nursing and Care Support in Sunshine',
			keywords:
				'NDIS, Community Nursing, Care Support, Sunshine, Disability Services',
		},
	},
	{
		path: '/service-areas/personal-support-care-sunshine',
		name: 'ndis-personal-support-care-sunshine',
		component: () =>
			import(
				/* webpackChunkName: "ndis-personal-support-care-sunshine" */ '@/views/services/ndis/location/sunshine/personal-support-care'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Personal Support Care in Sunshine',
			keywords: 'NDIS, Personal Support Care, Sunshine, Disability Programs',
		},
	},

	// ! Geelong
	{
		path: '/service-areas/community-nursing-geelong',
		name: 'ndis-community-nursing-geelong',
		component: () =>
			import(
				/* webpackChunkName: "ndis-community-nursing-geelong" */ '@/views/services/ndis/location/geelong/community-nursing'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Community Nursing and Care Support in Geelong',
			keywords:
				'NDIS, Community Nursing, Care Support, Geelong, Disability Services',
		},
	},
	{
		path: '/service-areas/personal-support-care-geelong',
		name: 'ndis-personal-support-care-geelong',
		component: () =>
			import(
				/* webpackChunkName: "ndis-personal-support-care-geelong" */ '@/views/services/ndis/location/geelong/personal-support-care'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Personal Support Care in Geelong',
			keywords: 'NDIS, Personal Support Care, Geelong, Disability Programs',
		},
	},

	// ! Melbourne
	{
		path: '/service-areas/community-nursing-melbourne',
		name: 'ndis-community-nursing-melbourne',
		component: () =>
			import(
				/* webpackChunkName: "ndis-community-nursing-melbourne" */ '@/views/services/ndis/location/melbourne/community-nursing'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Community Nursing and Care Support in Melbourne',
			keywords:
				'NDIS, Community Nursing, Care Support, Melbourne, Disability Services',
		},
	},
	{
		path: '/service-areas/personal-support-care-melbourne',
		name: 'ndis-personal-support-care-melbourne',
		component: () =>
			import(
				/* webpackChunkName: "ndis-personal-support-care-melbourne" */ '@/views/services/ndis/location/melbourne/personal-support-care'
			),
		meta: {
			requiresAuth: false,
			title: 'NDIS Personal Support Care in Melbourne',
			keywords: 'NDIS, Personal Support Care, Melbourne, Disability Programs',
		},
	},
]
