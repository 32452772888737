import * as types from '../mutation-types'
import ApiRequest from '@/services/services'

export default {
	async adminLogin({ commit, dispatch }, payload) {
		await ApiRequest.auth
			.adminLogin(payload)
			.then((response) => {
				dispatch(
					'common/displayToastAlert',
					{ title: response.data?.message },
					{
						root: true,
					}
				)
				commit(types.API_TOKEN, response.data?.token)
			})
			.catch((error) => {
				const errorResponse = error.response
				console.log(errorResponse)
				dispatch('common/displayErrorMessage', errorResponse, {
					root: true,
				})
			})
	},
}
