import * as types from '@/store/mutation-types'
import ApiRequest from '@/services/services'

/**
 * @module store/contact/contact-actions
 */

export default {
	sendEnquiry({ commit }, payload) {
		ApiRequest.contact
			.sendEnquiry(payload)
			.then((response) => {
				commit(types.CONTACT_RESPONSE, response)
			})
			.catch((error) => {
				console.log(error)
			})
	},
}
