import actions from './referral-actions'
import getters from './referral-getters'
import mutations from './referral-mutations'
import state from './referral-state'

/**
 * @module store/referral
 */

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state: state,
}
