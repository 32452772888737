import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'

//Importing Brand Specific Icons
import {
	faInstagram,
	faFacebook,
	faGoogle,
	faTwitter,
	faLinkedin,
} from '@fortawesome/free-brands-svg-icons'

//Import solid icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
	faSortDown,
	faSortUp,
	faBrain,
	faArrowRight,
	faPlus,
	faXmark,
	faLocationDot,
	faEnvelope,
	faPhoneVolume,
	faLocationCrosshairs,
	faUserNurse,
	faClock,
	faBullseye,
	faEye,
	faHouseChimney,
	faWheelchairMove,
	faMugHot,
	faUsersLine,
	faHandSparkles,
} from '@fortawesome/free-solid-svg-icons'

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
library.add(
	faSortUp,
	faSortDown,
	faInstagram,
	faGoogle,
	faBrain,
	faArrowRight,
	faPlus,
	faXmark,
	faLocationDot,
	faEnvelope,
	faPhoneVolume,
	faLocationCrosshairs,
	faUserNurse,
	faClock,
	faBullseye,
	faEye,
	faHouseChimney,
	faWheelchairMove,
	faMugHot,
	faUsersLine,
	faHandSparkles,
	faFacebook,
	faTwitter,
	faInstagram,
	faTwitter,
	faLinkedin
)
