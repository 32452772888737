import sendEnquiry from './contact/sendEnquiry'
import sendReferral from './referral/sendReferral'
import adminLogin from './auth/adminLogin'
import sendMassEmail from './admin/sales/sendMassEmail'

export default {
	contact: {
		sendEnquiry: sendEnquiry,
	},
	referral: {
		sendReferral: sendReferral,
	},
	auth: {
		adminLogin: adminLogin,
	},
	admin: {
		sales: {
			sendMassEmail: sendMassEmail,
		},
	},
}
