import actions from './common-actions'
import getters from './common-getters'
import mutations from './common-mutations'
import state from './common-state'

/**
 * @module store/common
 */

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state: state,
}
