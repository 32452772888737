/**
 * @module store/mutation-types
 */

/**
 * Contact Module
 */
export const CONTACT_RESPONSE = 'CONTACT_RESPONSE'

/**
 * Referral Module
 */
export const REFERRAL_RESPONSE = 'REFERRAL_RESPONSE'

/**
 * Auth Module
 */
export const API_TOKEN = 'API_TOKEN'

/**
 * Admin Module
 */
export const SEND_MASS_EMAIL_RESPONSE = 'SEND_MASS_EMAIL_RESPONSE'
