import Vue from 'vue'
import Vuex from 'vuex'
import contactModule from './contact/contact-module'
import commonModule from './common/common-module'
import referralModule from './referral/referral-module'
import authModule from './auth/auth-module'
import adminModule from './admin/admin-module'

Vue.use(Vuex)

/**
 * Modules part of this store.
 */
const modules = {
	contact: contactModule,
	common: commonModule,
	referral: referralModule,
	auth: authModule,
	admin: adminModule,
}

export const storeConfig = {
	modules,
	strict: false,
	plugins: [],
}

export default new Vuex.Store(storeConfig)
