import actions from './admin-actions'
import getters from './admin-getters'
import mutations from './admin-mutations'
import state from './admin-state'

/**
 * @module store/admin
 */

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state: state,
}
