import Vue from 'vue'
import DOMPurify from 'dompurify'

Vue.mixin({
	methods: {
		$sanitize(content) {
			return DOMPurify.sanitize(content)
		},
	},
})
