import * as types from '@/store/mutation-types'

/**
 * @module store/contact/mutations
 */

export default {
	[types.CONTACT_RESPONSE](state, response) {
		state.contact.response = response
	},
}
