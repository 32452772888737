import actions from './auth-actions'
import getters from './auth-getters'
import mutations from './auth-mutations'
import state from './auth-state'

/**
 * @module store/auth
 */

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state: state,
}
