import actions from './contact-actions'
import getters from './contact-getters'
import mutations from './contact-mutations'
import state from './contact-state'

/**
 * @module store/contact
 */

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state: state,
}
